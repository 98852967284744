import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa6";
import { IoPeopleSharp } from "react-icons/io5";

const Dashboard = ({ users = [], error, loading }) => {
    const countUsersByMethod = (method, isActive) => {
        return users.filter(user => user.loginType === method && user.status === (isActive ? 'active' : 'inactive')).length;
    };


    const TotalGenderCount = (gender) => {
        return users.filter(user => user.gender && user.gender.toLowerCase() === gender.toLowerCase()).length;
    };

    const Totaluser = (field, value) => {
        return users.filter(user => user[field] === value).length;
    };


    const data = [
        {
            name: 'Google',
            field: ['loginType', 'gender'],
            value: ['google', 'male', 'female'],
            icon: FaGoogle,
            active: countUsersByMethod('google', true), // Count of active Google users
            inactive: countUsersByMethod('google', false), // Count of inactive Google users
            graphData: [5, 4, 3, 6],
            graphColor: '#4CAF50',
        },
        {
            name: 'Facebook',
            field: ['loginType', 'gender'],
            value: ['facebook', 'male', 'female'],
            icon: FaFacebook,
            active: countUsersByMethod('facebook', true), // Count of active Facebook users
            inactive: countUsersByMethod('facebook', false), // Count of inactive Facebook users
            graphData: [2, 1, 3, 1],
            graphColor: '#FFA500',
        },
        {
            name: 'Apple',
            field: ['loginType', 'gender'],
            value: ['apple', 'male', 'female'],
            icon: FaApple,
            active: countUsersByMethod('apple', true), // Count of active Apple users
            inactive: countUsersByMethod('apple', false), // Count of inactive Apple users
            graphData: [3, 4, 2, 3],
            graphColor: '#708090',
        },
        {
            name: 'Normal',
            field: ['loginType', 'gender'],
            value: ['normal', 'male', 'female'],
            icon: IoPeopleSharp,
            active: countUsersByMethod('normal', true), // Count of active Normal users
            inactive: countUsersByMethod('normal', false), // Count of inactive Normal users
            graphData: [4, 2, 3, 5],
            graphColor: '#20B2AA',
        }
    ];
    

    const totalUserData = data.map(item => ({
        name: item.name,
        y: Totaluser(item.field[0], item.value[0]),
    }));

    const totalGenderData = [
        {
            name: 'Male',
            y: TotalGenderCount('male'), // Total male users
        },
        {
            name: 'Female',
            y: TotalGenderCount('Female'), // Total female users
        },
    ];

    const TotaluserchartOptions = {
        chart: {
            type: 'pie',
            events: {
                render() {
                    const chart = this,
                        series = chart.series[0];
                        if (!chart.options.chart.custom) {
                            chart.options.chart.custom = {};
                        }
                    let customLabel = chart.options.chart.custom.label;

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(
                                'Total User<br/>' + users.length
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle'
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop - (customLabel.attr('height') / 2);

                    customLabel.attr({ x, y });
                    customLabel.css({ fontSize: `${series.center[2] / 12}px` });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            pointFormat: ''
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 8,
                dataLabels: [{
                    enabled: true,
                    distance: 20,
                    format: '{point.name}'
                }, {
                    enabled: true,
                    distance: -15,
                    format: '{point.percentage:.0f}%',
                    style: {
                        fontSize: '0.9em'
                    }
                }],
                showInLegend: true
            }
        },
        series: [{
            name: 'Login Types',
            colorByPoint: true,
            innerSize: '75%',
            data: totalUserData
        }]
    };

    const TotalgenderchartOptions = {
        chart: {
            type: 'pie',
            events: {
                render() {
                    const chart = this,
                        series = chart.series[0];
                        if (!chart.options.chart.custom) {
                            chart.options.chart.custom = {};
                        }
                    let customLabel = chart.options.chart.custom.label;

                    const totalMale = TotalGenderCount('male');
                    const totalFemale = TotalGenderCount('Female');

                    if (!customLabel) {
                        customLabel = chart.options.chart.custom.label =
                            chart.renderer.label(
                                `Male: ${totalMale} <br/> Female: ${totalFemale}`
                            )
                                .css({
                                    color: '#000',
                                    textAnchor: 'middle'
                                })
                                .add();
                    }

                    const x = series.center[0] + chart.plotLeft,
                        y = series.center[1] + chart.plotTop - (customLabel.attr('height') / 2);

                    customLabel.attr({ x, y });
                    customLabel.css({ fontSize: `${series.center[2] / 12}px` });
                }
            }
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            pointFormat: ''
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderRadius: 8,
                dataLabels: [{
                    enabled: true,
                    distance: 20,
                    format: '{point.name}'
                }, {
                    enabled: true,
                    distance: -15,
                    format: '{point.percentage:.0f}%',
                    style: {
                        fontSize: '0.9em'
                    }
                }],
                showInLegend: true
            }
        },
        series: [{
            name: 'Gender Distribution',
            colorByPoint: true,
            innerSize: '75%',
            data: totalGenderData // Gender distribution
        }]
    };

    const chartOptions = {
        chart: {
            type: 'areaspline',
            backgroundColor: 'transparent',
            height: '30%',
        },
        title: {
            text: '',
        },
        xAxis: {
            visible: false,
        },
        yAxis: {
            visible: false,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
    };

    return (
        <div className="p-6 h-full md:h-[90vh] overflow-y-scroll bg-[#F5F6FA]">
            <h1 className="text-3xl font-bold mb-4 text-[#3552d1]">Dashboard</h1>

            {loading && <div className="text-[#3552d1]">Loading...</div>}
            {error && <div className="bg-red-100 text-red-600 p-4 rounded mb-4">{error}</div>}

            <div className="grid gap-4">
                <div className="grid xl:grid-cols-4 md:grid-cols-2 gap-3 md:gap-5">
                    {data.map((list, index) => {
                        return (
                            <div key={index}>
                                <div className='rounded-lg cursor-pointer hover:scale-105 transition-all duration-500 hover:shadow-xl flex-row bg-white shadow-sm py-4 px-3 items-start'>
                                    <div className='flex'>
                                        <div className={`flex justify-center items-center mt-3 rounded-full text-white p-2`} style={{ backgroundColor: list.graphColor }}>
                                            <list.icon className='xl:text-4xl text-2xl' />
                                        </div>
                                        <div className='flex flex-col ms-5'>
                                            <span className='text-gray-400 text-nowrap'>{list.name}</span>
                                            <span className='xl:text-3xl text-xl font-medium mt-1 flex gap-2'>
                                                <span>Total:</span>
                                                {Totaluser('loginType', list.name.toLowerCase())}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='xl:text-3xl text-xl font-medium mt-3 flex justify-evenly place-items-center'>
                                         <span>Active: {list.active}</span>
                                    <span className='ml-5'>Inactive: {list.inactive}</span>
                                    </div>
                                    <div className='h-1/2'>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={{
                                                ...chartOptions,
                                                series: [{
                                                    data: list.graphData,
                                                    color: list.graphColor,
                                                    marker: { enabled: false },
                                                    fillColor: {
                                                        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                                                        stops: [
                                                            [0, list.graphColor],
                                                            [1, 'transparent']
                                                        ]
                                                    },
                                                }]
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="grid lg:grid-cols-2 gap-4 mt-4">
                    <div className='p-4 rounded-lg bg-white shadow-sm'>
                        <h2 className="text-xl font-semibold mb-4">Total User Chart</h2>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={TotaluserchartOptions}
                        />
                    </div>
                    <div className='p-4 rounded-lg bg-white shadow-sm'>
                        <h2 className="text-xl font-semibold mb-4">Total Gender Chart</h2>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={TotalgenderchartOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
