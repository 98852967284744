import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserState } from './Context';

const Routelogin = ({ children }) => {
  const { user } = UserState(); // Access user from context

  // Check if user is authenticated
  if (!user || typeof user !== 'object') {
    return <Navigate to="/login" replace />;
  }

  // Render the child components if user is authenticated
  return children;
};

export default Routelogin;
