    import React, { useState } from 'react';
    import '../../assets/Css/Style.css';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';
    import { BsEyeFill } from 'react-icons/bs';
    import logo from '../../assets/Images/logo.png'
    import { IoClose } from "react-icons/io5";



    const Userpage = ({ error, users, loading }) => {
        const [searchTerm, setSearchTerm] = useState('');
        const [sortBy, setSortBy] = useState('');
        const [sortOrder, setSortOrder] = useState('asc');
        const [currentPage, setCurrentPage] = useState(1);
        const [selectedUser, setSelectedUser] = useState(null);
        const usersPerPage = 10;
        const defaultImage = logo;


        // Handle search input change
        const handleSearchChange = (e) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
        };

        const handleSort = (field) => {
            if (sortBy === field) {
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            } else {
                setSortBy(field);
                setSortOrder('asc');
            }
            setCurrentPage(1);
        };

        const filteredUsers = users.filter((user) =>
            user.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.gender?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.country?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.state?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.mobile?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.mobileCode?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            user.email?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        // Sort the users array based on the selected field and order
        const sortedUsers = filteredUsers.sort((a, b) => {
            if (!sortBy) return 0;
            const fieldA = a[sortBy]?.toString().toLowerCase();
            const fieldB = b[sortBy]?.toString().toLowerCase();
            if (sortOrder === 'asc') {
                return fieldA > fieldB ? 1 : -1;
            } else {
                return fieldA < fieldB ? 1 : -1;
            }
        });

        const indexOfLastUser = currentPage * usersPerPage;
        const indexOfFirstUser = indexOfLastUser - usersPerPage;
        const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

        const paginate = (pageNumber) => {
            const maxPage = Math.ceil(sortedUsers.length / usersPerPage);
            if (pageNumber <= maxPage && pageNumber > 0) {
                setCurrentPage(pageNumber);
            }
        };

        const showUserDetails = (user) => {
            setSelectedUser(user);
        };

        const closeDetails = () => {
            setSelectedUser(null);
        };

        const downloadPDF = () => {
            const doc = new jsPDF();
            doc.setFontSize(16);
            doc.text("User Profiles", 20, 20);
            doc.setFontSize(12);

            // Set table headers
            const headers = [["Name", "Mobile No", "Email", "City Name"]];
            const data = filteredUsers.map(user => [user.name, user.mobile, user.email, user.city]);

            // Create a table in the PDF
            doc.autoTable({
                head: headers,
                body: data,
                startY: 30,
                theme: 'grid',
                headStyles: {
                    fillColor: [22, 160, 133],
                    textColor: [255, 255, 255],
                    fontSize: 14,
                },
                styles: {
                    cellPadding: 4,
                    fontSize: 12,
                    halign: 'center',
                    valign: 'middle',
                },
                margin: { top: 10 },
            });

            doc.save('users.pdf');
        };

        const downloadCSV = () => {
            const csvData = [
                ["Name", "gender", "Mobile No", "Email", "City Name", "country Name", "state Name"],
                ...filteredUsers.map(user => [user.name, user.gender, user.mobile, user.email, user.city, user.country, user.state]),
            ];

            const csvContent = "data:text/csv;charset=utf-8," +
                csvData.map(e => e.join(",")).join("\n");

            const encodedUri = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "users.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        
        return (
            <div className="p-4 h-full md:h-screen overflow-y-scroll bg-[#F5F6FA]">
                <h1 className="text-3xl font-bold mb-4 text-[#3552d1]">User Profiles</h1>

                <div className="flex justify-between">
                    <div className="w-1/2">
                        <input
                            type="text"
                            placeholder="Search by name, mobile, or email..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="p-2 rounded-lg mb-4 w-full glass1"
                        />
                    </div>

                    <div className="flex gap-4 place-items-end justify-end">
                        <button
                            onClick={downloadPDF}
                            className="bg-[#3552d1] text-white py-2 px-4 rounded-lg mb-4  transition"
                        >
                            Download PDF
                        </button>
                        <button
                            onClick={downloadCSV}
                            className="bg-black text-white py-2 px-4 rounded-lg mb-4 transition"
                        >
                            Download CSV
                        </button>
                    </div>
                </div>

                <table className="min-w-full rounded-lg">
                    <thead>
                        <tr className='text-left'>
                            <th className="py-4 px-4 border-b">Sr No.</th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('name')}
                            >
                                Name {sortBy === 'name' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('gender')}
                            >
                                Gender {sortBy === 'gender' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('mobile')}
                            >
                                Mobile No {sortBy === 'mobile' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('email')}
                            >
                                Email {sortBy === 'email' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('city')}
                            >
                                City Name {sortBy === 'city' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('city')}
                            >
                                Cuntry Name {sortBy === 'city' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                            <th
                                className="py-4 px-4 border-b cursor-pointer"
                                onClick={() => handleSort('city')}
                            >
                                State Name {sortBy === 'city' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                            </th>
                        </tr>
                    </thead>

                    <tbody className="shadow-xl bg-white">
                        {currentUsers.map((user, index) => (
                            <tr key={index} className="hover:bg-gray-300 transition">

                                <td className="py-4 px-4 border-b">{indexOfFirstUser + index + 1}</td>
                                <td className="py-4 px-4 border-b">{user.name}</td>
                                <td className="py-4 px-4 border-b">{user.gender}</td>
                                <td className="py-4 px-4 border-b">{user.mobileCode} {user.mobile}</td>
                                <td className="py-4 px-4 border-b">{user.email}</td>
                                <td className="py-4 px-4 border-b">{user.city}</td>
                                <td className="py-4 px-4 border-b">{user.country}</td>
                                <td className="py-4 px-4 border-b">{user.state}</td>

                                {/* Add the profile button here */}
                                <td className="py-4 px-4 border-b">
                                    <button
                                        className=" py-1 px-3 rounded-lg  transition"
                                        onClick={() => showUserDetails(user)}
                                    >
                                        <BsEyeFill />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>

                <div className="flex justify-center mt-4">
                    {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }).map((_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`mx-1 py-1 px-3 rounded-lg ${currentPage === index + 1 ? 'bg-[#3552d1] text-white' : 'bg-gray-200'}`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>

                {selectedUser && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 h-screen w-full">
                        <div className="bg-white p-8 rounded-lg w-1/2 shadow-xl h-5/6 overflow-y-scroll">
                            <div className='flex justify-between'>
                            <h2 className="text-xl font-bold mb-4">User Details</h2>
                            <IoClose  onClick={closeDetails} className='cursor-pointer text-2xl'/>
                            </div>
                            <img
                                src={selectedUser.photoURL && selectedUser.photoURL !== "" ? selectedUser.photoURL : defaultImage}
                                alt={`${selectedUser.name}'s photo`}
                                className='rounded-full w-32 h-32 mb-4'
                            />

                            {/* User Information */}
                            <table className="w-full border border-gray-300 mb-4">
                                <tbody>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Name:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.name || '--'}</td>
                                        <td className="border px-4 py-2"><strong>Gender:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.gender || '--'}</td>
                                    </tr>

                                    <tr>
                                        <td className="border px-4 py-2"><strong>Mobile:</strong></td>
                                        <td className="border px-4 py-2">{`${selectedUser.mobileCode || '--'} ${selectedUser.mobile || ''}`}</td>
                                        <td className="border px-4 py-2"><strong>Email:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.email || '--'}</td>
                                    </tr>

                                    <tr>
                                        <td className="border px-4 py-2"><strong>City:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.city || '--'}</td>
                                        <td className="border px-4 py-2"><strong>Country:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.country || '--'}</td>
                                    </tr>

                                    <tr>
                                        <td className="border px-4 py-2"><strong>State:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.state || '--'}</td>
                                        <td className="border px-4 py-2"><strong>Uid:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.uid || '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Login Type:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.loginType || '--'}</td>
                                        <td className="border px-4 py-2"><strong>Last Login:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.lastLogin ? new Date(selectedUser.lastLogin).toLocaleString() : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Created At:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.createdAt ? new Date(selectedUser.createdAt).toLocaleString() : '--'}</td>
                                        <td className="border px-4 py-2"><strong>Updated At:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.updatedAt ? new Date(selectedUser.updatedAt).toLocaleString() : '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Date of Birth:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.dateOfBirth ? new Date(selectedUser.dateOfBirth).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }) : 'N/A'}</td>
                                        <td className="border px-4 py-2"><strong>Favorite Dance Style:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.favoriteDanceStyle || '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Language:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.language || '--'}</td>
                                        <td className="border px-4 py-2"><strong>Preferred Contact Method:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.preferredContactMethod || '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Relationship Status:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.relationshipStatus || '--'}</td>
                                        <td className="border px-4 py-2"><strong>Skill Level:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.skillLevel || '--'}</td>
                                    </tr>
                                    <tr>
                                        <td className="border px-4 py-2"><strong>Status:</strong></td>
                                        <td className="border px-4 py-2">{selectedUser.status || '--'}</td>
                                    </tr>
                                    
                                </tbody>
                            </table>


                            {/* Video Links Section */}
                            <h3 className="text-lg font-semibold mb-2">Video Links</h3>
                            <div className="max-h-40 overflow-y-scroll border border-gray-300 mb-4 p-2 rounded-lg">
                                {selectedUser.videoLinks.length > 0 ? (
                                    <table className="w-full border-collapse border border-gray-300">
                                        <thead>
                                            <tr className="bg-gray-100">
                                                <th className="py-2 px-4 border-b text-left">Sr No</th>
                                                <th className="py-2 px-4 border-b text-left">Link</th>
                                                <th className="py-2 px-4 border-b text-left">Count</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {selectedUser.videoLinks.map((video, index) => (
                                                <tr key={video._id} className="hover:bg-gray-50">
                                                    <td className="py-4 px-4 border-b">{index + 1}</td>
                                                    <td className="py-2 px-4 border-b overflow-hidden">
                                                        <a href={video.link} className="text-blue-500 hover:underline  block max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">
                                                            {video.link}
                                                        </a>
                                                    </td>
                                                    <td className="py-2 px-4 border-b">{video.count}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>No video links available.</p>
                                )}
                            </div>

                            {/* Close Button */}
                            <div className="text-center">
                                <button
                                    className="mt-4 bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 transition duration-200"
                                    onClick={closeDetails}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    export default Userpage;
