import React, { useState } from 'react';
import '../../assets/Css/Style.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const History = ({ popularityData, setUserid }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('totalLinkUse');
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;

    const navigate = useNavigate(); // Initialize useNavigate

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handleSort = (field) => {
        setSortBy(field);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setCurrentPage(1);
    };

    // Filter and sort users based on search term
    const filteredUsers = (popularityData || []).filter(user =>
        user.link?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedUsers = filteredUsers.sort((a, b) => {
        const fieldA = a[sortBy];
        const fieldB = b[sortBy];
        return sortOrder === 'asc' ? fieldA - fieldB : fieldB - fieldA;
    });

    // Pagination logic
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(16);
        doc.text("User Profiles", 20, 20);
        const headers = [["Link", "Total Link Use", "User Count"]];
        const data = filteredUsers.map(user => [user.link, user.totalLinkUse, user.userCount]);
        doc.autoTable({ head: headers, body: data, startY: 30, theme: 'grid' });
        doc.save('users.pdf');
    };

    const downloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," +
            [["Link", "Total Link Use", "User Count"], ...filteredUsers.map(user => [user.link, user.totalLinkUse, user.userCount])].map(e => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "users.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const viewUserDetail = (user) => {
        const userId = user.users.map(u => u.userId);
        setUserid(userId)

        if (userId.length > 0) {
            navigate(`/user/${userId[0]}`);
        }
    };

    return (
        <div className="p-4 h-full md:h-screen overflow-y-scroll bg-[#F5F6FA]">
            <h1 className="text-3xl font-bold mb-4 text-[#3552d1]">History</h1>
            <div className="flex justify-between">
                <div className="w-1/2">
                    <input
                        type="text"
                        placeholder="Search by link ..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="p-2 rounded-lg mb-4 w-full glass1"
                    />
                </div>
                <div className="flex gap-4 place-items-end justify-end">
                    <button onClick={downloadPDF} className="bg-[#3552d1] text-white py-2 px-4 rounded-lg mb-4 transition">
                        Download PDF
                    </button>
                    <button onClick={downloadCSV} className="bg-black text-white py-2 px-4 rounded-lg mb-4 transition">
                        Download CSV
                    </button>
                </div>
            </div>

            <table className="min-w-full rounded-lg">
                <thead>
                    <tr className='text-left'>
                        <th className="py-4 px-4 border-b">Sr No.</th>
                        <th className="py-4 px-4 border-b cursor-pointer" onClick={() => handleSort('link')}>
                            Link {sortBy === 'link' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                        </th>
                        <th className="py-4 px-4 border-b cursor-pointer" onClick={() => handleSort('totalLinkUse')}>
                            Link Count {sortBy === 'totalLinkUse' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                        </th>
                        <th className="py-4 px-4 border-b cursor-pointer" onClick={() => handleSort('userCount')}>
                            User Count {sortBy === 'userCount' ? (sortOrder === 'asc' ? '↑' : '↓') : ''}
                        </th>
                        <th className="py-4 px-4 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody className="shadow-xl bg-white">
                    {currentUsers.map((user, index) => (
                        <tr key={user.link} className="hover:bg-gray-300 transition">
                            <td className="py-4 px-4 border-b">{indexOfFirstUser + index + 1}</td>
                            <td className="py-4 px-4 border-b overflow-hidden">
                                <a href={user.link} className="text-blue-500 hover:underline block max-w-xs overflow-hidden text-ellipsis whitespace-nowrap">
                                    {user.link}
                                </a>
                            </td>
                            <td className="py-4 px-4 border-b">{user.totalLinkUse}</td>
                            <td className="py-4 px-4 border-b">{user.userCount}</td>
                            <td className="py-4 px-4 border-b">
                                <button className="py-1 px-3 rounded-lg transition" onClick={() => viewUserDetail(user)}>
                                    <BsEyeFill />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-center mt-4">
                {Array.from({ length: Math.ceil(filteredUsers.length / usersPerPage) }).map((_, index) => (
                    <button
                        key={index + 1}
                        onClick={() => paginate(index + 1)}
                        className={`mx-1 py-1 px-3 rounded-lg ${currentPage === index + 1 ? 'bg-[#3552d1] text-white' : 'bg-gray-200'}`}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default History;
