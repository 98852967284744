import React from 'react';
import { FaUserFriends } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../assets/Images/logo.png';
import { RiHistoryFill } from "react-icons/ri";


const Sidebar = ({ isOpen }) => {
    const navLinks = [
        { to: '/user', icon: <FaUserFriends className="text-2xl" />, label: 'User' },
        { to: '/history', icon: <RiHistoryFill className="text-2xl" />, label: 'History' },
    ];

    return (
        <div className="h-full glass1 text-white border">
            <div className={` transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full sm:translate-x-0'}  bg-white`}>
                <div className="flex items-center justify-center px-4 py-2 relative">
                    <Link to='/' className='flex gap-4 justify-center items-center'>
                        <img src={logo} alt="Logo" className={`transition-all duration-300 ${!isOpen ? 'w-10' : 'w-12'}`} />
                        <span className={`transition-all duration-300 text-3xl font-bold mt-2 text-[#3552d1] ${!isOpen ? 'hidden' : 'block'}`}>Dashboard</span>
                    </Link>
                </div>
                <nav className="flex flex-col p-4 space-y-4">
                    {navLinks.map(({ to, icon, label }) => (
                        <NavLink
                            to={to}
                            key={label}
                            className={({ isActive }) =>
                                `p-2 rounded flex items-center gap-4 transition-all duration-300 ${
                                    isActive ? 'bg-[#3552d1] text-white ' : ' text-black hover:text-[#3552d1]'
                                } ${!isOpen ? 'justify-center' : ''}`
                            }
                        >
                            {icon}
                            <span className={`font-bold ${!isOpen ? 'hidden' : 'block'}`}>{label}</span>
                        </NavLink>
                    ))}
                </nav>
            </div>
        </div>
    );
};

export default Sidebar;
