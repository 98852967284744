import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Create the context
const AuthContext = createContext();

// Create a provider component
const AuthProvider = ({ children }) => {

  const updateUser = (updatedUser) => {
    setUser(updatedUser);
  };
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const [loading , setLoading] = useState(true);

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      try {
        // console.log('loginssssss')
        const parsedUser = JSON.parse(userInfo);
        setUser(parsedUser);
      } catch (error) {
        console.error("Failed to parse user info:", error);
        setUser(""); 
      }
    } else {
      setUser('');
      // navigate('/');
    }
    setLoading(false);
    
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const Logout = () => {
    localStorage.removeItem('userInfo');
    setUser('');
    navigate('/login');
  };

  return (
    <AuthContext.Provider value={{ user, setUser, Logout, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserState = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
