import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './Dashboard'
import Userpage from './Userpage'
import ForgotPassword from './ForgotPassword'
import ProfilePage from './Profile'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { UserState } from './Context'
import Routelogin from './Routelogin'
import History from './History'
import Userdetail from './Userdetail'

const Landingpage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [loading, setLoading] = useState(false);
  const [popularityData, setPopularityData] = useState(null);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [userid , setUserid] = useState([]);

  const { user } = UserState(); // Get user context


  const sortUsersAlphabetically = (usersArray) => {
    return usersArray.sort((a, b) => a.name.localeCompare(b.name));
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://backend-api-three-mu.vercel.app/api/admin/getAllUser', {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        });

        if (response.data.sucess) {
          // console.log(response.data.data)
          const sortedUsers = sortUsersAlphabetically(response.data.data);
          setUsers(sortedUsers);

        } else {
          setUsers([]);
        }
      } catch (err) {
        setError('Failed to fetch users.');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [user.token]);


  useEffect(() => {
    const fetchPopularityData = async () => {
      try {
        setLoading(true);
        const response = await axios.get('https://backend-api-three-mu.vercel.app/api/admin/getPopularity', {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        });

        if (response.data.success) {
          setPopularityData(response.data.data);
        } else {
          setPopularityData([]);
        }
      } catch (err) {
        setError('Failed to fetch popularity data.');
      } finally {
        setLoading(false);
      }
    };

    fetchPopularityData();
  }, [user.token]);



  return (
    <>

      <div className="w-full h-full md:h-screen flex">
        <div className={`transition-all duration-300 ${isOpen ? 'w-1/6' : 'w-24'}`}>
          <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        </div>
        <div className="flex flex-col flex-grow">
          <Navbar isOpen={isOpen} toggleSidebar={toggleSidebar} />

          <div className="flex-grow">
            <Routes>

            <Route path="/user/:userId" element={<Userdetail  userid={userid}/>} />
              <Route
                path="/"
                element={
                  <Routelogin>
                    <Dashboard loading={loading} error={error} users={users} />
                  </Routelogin>
                }
              />
              <Route
                path="/user"
                element={
                  <Routelogin>
                    <Userpage loading={loading} error={error} users={users} />
                  </Routelogin>
                }
              />
              <Route
                path="/history"
                element={
                  <Routelogin>
                    <History loading={loading} error={error} popularityData={popularityData} users={users} setUserid={setUserid}/>
                  </Routelogin>
                }
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/profile"
                element={
                  <Routelogin>
                    <ProfilePage />
                  </Routelogin>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  )
}

export default Landingpage
