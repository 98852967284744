import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import icon from '../../assets/Images/icon.png';
import img from '../../assets/Images/Ellipse 130 (6).png';
import { UserState } from './Context';

const Navbar = ({ toggleSidebar }) => {
    const { user, Logout } = UserState();
    const [showProfile, setShowProfile] = useState(false); // Manage hover state

    return (
        <div className="flex w-full items-center py-2 justify-between glass1 text-white border">
            <div className='relative h-10 w-10'>
                <button onClick={toggleSidebar} className="focus:outline-none font-color z-50 absolute -left-3 top-2 xl:flex hidden">
                    <img src={icon} alt="Icon" className="transition-transform" />
                </button>
            </div>

            <div className="flex items-center place-items-center gap-6 px-4  w-fit">
                {/* Profile Section */}
                <div
                    className="relative"
                    onMouseEnter={() => setShowProfile(true)}
                    onMouseLeave={() => setShowProfile(false)}
                >
                    <img
                        src={img}
                        alt="Profile"
                        className="w-12 h-12 rounded-2xl object-cover cursor-pointer"
                    />
                    {user && (
                        <div className="w-fit flex items-center justify-center cursor-pointer">
                            <p className="text-sm text-gray-500 text-center">Admin</p>

                        </div>
                    )}

                    {/* Hover Profile Details */}
                    {showProfile && user && (
                        <div className="absolute right-0 top-14 bg-white p-4 rounded-lg shadow-lg text-black z-10 flex gap-4 justify-center items-center">

                            {/* <p className="text-sm text-gray-500">{user.email}</p> */}
                            <Link to="/profile" className="">
                                <button

                                    className=" bg-black text-white py-1 px-3 rounded"
                                >
                                    Profile
                                </button>
                            </Link>
                            <button
                                onClick={Logout}
                                className="bg-[#3552d1] text-white py-1 px-3 rounded"
                            >
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
