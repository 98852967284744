import Login from "./Admin/Component/Login";
import { Route, Routes } from 'react-router-dom';
import Landingpage from './Admin/Component/Landingpage';


function App() {



  return (
    <>
      <div className="w-full flex h-screen">
        <Routes>
          <Route path='/*' element={<Landingpage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </>
  );
}


export default App;
