import React, { useState } from 'react';
import { UserState } from './Context';
import axios from 'axios';
import { FaUser } from 'react-icons/fa';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';

const ProfilePage = () => {
  const { user, updateUser } = UserState();
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [editableUser, setEditableUser] = useState(user);
  const [passwordData, setPasswordData] = useState({ currentPassword: '', newPassword: '', confirmPassword: '' });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
    setEditableUser(user);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditableUser({ ...editableUser, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsEditing(false);
    updateUser(editableUser); // Ensure user state updates here
    console.log('Updated User Info:', editableUser);
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (passwordData.newPassword !== passwordData.confirmPassword) {
      alert("New password and confirm password do not match!");
      return;
    }

    try {
      const response = await axios.post('https://backend-api-three-mu.vercel.app/api/admin/change-password', {
        email: user.email, 
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      }, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status === 200) {
        alert('Password updated successfully!');
        setPasswordData({ currentPassword: '', newPassword: '', confirmPassword: '' });
        setIsUpdatingPassword(false);
      }
    } catch (error) {
      console.error('Error updating password:', error);
      alert('Failed to update password. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="w-full max-w-xl p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold text-center text-gray-700 mb-6">Profile</h1>

        { isUpdatingPassword ? (
          <form onSubmit={handlePasswordSubmit} className="space-y-6">
            {/* Update Password Form */}
            <div className="flex flex-col">
              <label className="text-lg font-semibold text-gray-600" htmlFor="currentPassword">Current Password:</label>
              <div className="relative">
                <input
                  type={showCurrentPassword ? 'text' : 'password'}
                  id="currentPassword"
                  name="currentPassword"
                  value={passwordData.currentPassword}
                  onChange={handlePasswordChange}
                  className="border border-gray-300 p-3 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  className="absolute right-2 top-3"
                >
                  {showCurrentPassword ? <AiFillEye className='text-2xl'/> : <AiFillEyeInvisible className='text-2xl'/>}
                </button>
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-lg font-semibold text-gray-600" htmlFor="newPassword">New Password:</label>
              <div className="relative">
                <input
                  type={showNewPassword ? 'text' : 'password'}
                  id="newPassword"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  className="border border-gray-300 p-3 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  className="absolute right-2 top-3"
                >
                  {showNewPassword ? <AiFillEye className='text-2xl'/> : <AiFillEyeInvisible className='text-2xl'/>}
                </button>
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-lg font-semibold text-gray-600" htmlFor="confirmPassword">Re-enter New Password:</label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordChange}
                  className="border border-gray-300 p-3 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute right-2 top-3"
                >
                  {showConfirmPassword ? <AiFillEye className='text-2xl'/> : <AiFillEyeInvisible className='text-2xl'/>}
                </button>
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition duration-200"
            >
              Update Password
            </button>
          </form>
        ) : (
          <div className="space-y-4">
            {/* Display User Details */}
            <div className="bg-gray-50 p-4 rounded-md shadow">
              <h2 className="text-xl font-semibold text-gray-800">
                <FaUser className="inline mr-2" /> {/* User Icon */}
                User Details
              </h2>
              <p className="text-gray-600 "><strong>Name :</strong> Admin</p>
              <p className="text-gray-600"><strong>Email :</strong> {user.email}</p>
            </div>

          
            <button
              onClick={() => setIsUpdatingPassword(true)}
              className="w-full bg-blue-600 text-white p-3 rounded-md hover:bg-blue-700 transition duration-200"
            >
              Change Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
